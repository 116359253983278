@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/roboto');

:root {
    --primary: #06A3DA;
    --secondary: #34AD54;
    --light: #EEF9FF;
    --dark: #091E3E;
    --darkBlack: #6b6a75;
}

/*** Heading ***/
h1,
h2,
.fw-bold {
    font-weight: 800 !important;  
}

h3,
h4,
.fw-semi-bold {
    font-weight: 700 !important;
}

h5,
h6,
.fw-medium {
    font-weight: 600 !important;
}

.sectionText h5, h1{
    font-family: 'Poppins', sans-serif;               
}
.logo-npl{
    width: 195px;
    height: auto;
}
.textOn{
    /* font-weight: 400; */
    color: #6b6a75;
    font-family: 'Roboto Light', sans-serif;  
}
.textOn2{
    /* font-weight: 600; */
    color: #6b6a75;
    font-family: 'Roboto Light', sans-serif;  
    line-height: 22px;
}
.textOn3{
    font-family: 'Roboto Light', sans-serif;  
    /* font-weight: 600; */
    color: #6b6a75;
}
/* *** Button ***/ 
.btn {
    font-family: 'Nexa', sans-serif;  
    /* font-weight: 600; */
    transition: .5s;
}
.misionBtn{
    background-color: #1555A2;
    color: white;
    font-family: 'Roboto Medium', sans-serif;
    margin-top: 12px;
    
}
.bg-bluecolor{
    background-color: #1555A2;
}
.three_box {
    margin-top: -100px;
    z-index: 9;
    position: relative;
    padding: 0 20px;
    justify-content: space-between;
}
.custom-ordered-list {
    list-style-type: decimal; /* or use other values like 'lower-alpha', 'upper-roman', etc. */
    margin-left: 20px; /* Adjust the left margin as needed */
    padding-left: 10px; /* Adjust the left padding as needed */
  }
  
  .custom-ordered-list li {
    margin-bottom: 8px; /* Adjust the space between list items as needed */
  }
  
.custom-div {
    padding: 40px 0px ;
    background: #FF931E;
    /* box-shadow: 0 0 250px rgba(3, 3, 3, 0.16); */
    transition: ease-in all 0.5s;
    border-radius: 10px;
    text-align: center;
}
#text_hover1.custom-div{
    background-color: var(--primary);
}
#text_hover1.custom-div:hover{
    background: url(./img/tennisOnHover.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#text_hover2.custom-div{
    background-color: blue;
}
.custom-div:hover{
    background: url(./img/germia.jpg);
    background-position:bottom;
    background-repeat: no-repeat;
    background-size: cover;
}
#text_hover3.custom-div{
    background-color: #5CCE20;
}
#text_hover3.custom-div:hover{
    background: url(./img/amfiteatriOnHover.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#text_hover4.custom-div{
    background-color: #1555A2;
}
#text_hover4.custom-div:hover{
    background-image: url(./img/1234marketing.png);
}
.const span {
    transition: ease-in all 0.5s;
    display: block;
    font-size: 21px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif; 
    color: white;
    padding-top: 35px;
    line-height: 20px;
}

#text_hover:hover.const {
    background-color: #f89501;
    transition: ease-in all 0.5s;
    cursor: pointer;
}

#text_hover:hover.const span {
    color: #fff;
    transition: ease-in all 0.5s;
}
.btn-primary,
.btn-secondary {
    color: #FFFFFF;
    box-shadow: inset 0 0 0 50px transparent;
}

.btn-primary:hover {
    box-shadow: inset 0 0 0 0 var(--primary);
}

.btn-secondary:hover {
    box-shadow: inset 0 0 0 0 var(--secondary);
}

.btn-square {
    width: 36px;
    height: 36px;
}

.btn-sm-square {
    width: 30px;
    height: 30px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.spinner {
    width: 40px;
    height: 40px;
    background: var(--primary);
    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(9, 30, 62, 0.212);
    z-index: 1;
    font-family: 'Roboto', sans-serif;
}

.bg-footerBackground{
    background: #1555A2;
}
@media (max-width: 576px) {
    .carousel-caption h5 {
        font-size: 14px;
        font-weight: 500 !important;
    }

    .carousel-caption h1 {
        font-size: 30px;
        font-weight: 600 !important;
    }
}

.carousel-control-prev,
.carousel-control-next {
    width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
}

@keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px)
    }
    50% {
        -webkit-transform: perspective(120px) rotateY(180deg)
    }
    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Service ***/
.service-item {
    position: relative;
    height: 300px;
    padding: 0 30px;
    transition: .5s;
    font-weight: 600;
    color: #6b6a75;
    font-family: 'Nexa', sans-serif;  
}
.section-title h5{
    font-family: 'Nexa', sans-serif;  
}
.service-item .service-icon {
    margin-bottom: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 2px;
    transform: rotate(-45deg);
}
.btn-outline-light:hover{
    transform: scale(1.2);
}
.service-item .service-icon i {
    transform: rotate(45deg);
}

.service-item a.btn {
    position: absolute;
    width: 135px;
    height: 47px;
    bottom: -23px;
    left: 50%;
    margin-left: -63px;
    opacity: 1;

}
.bi-arrow-right{
    font-size: 18px;
    margin-left: -4px;
    font-weight: bold;
   
}
.textPoppins{
    font-family: 'Poppins', sans-serif;  
}

.footer-about{
    font-family: 'Roboto Medium', sans-serif;  
    /* font-weight: 700; */
}
.Background{
    background: #1555A2;
}
.section-title h3{
    font-family: 'Nexa', sans-serif;  
}
.section-title1 h3{
    font-family: 'Nexa', sans-serif;  
}
/* shikoje */
.link-animated a {
    transition: .5s;
}

.link-animated a:hover {
    padding-left: 10px;
}
.tefteri{
    margin: 0 auto;
}
.staffNpl{
    height: 50vh;
    width: 80%;
    object-fit: cover;
    margin: 0 auto;
}
.textNplBg{
    width: 80%;
    margin: 0 auto;
}
li{
    list-style: none; 
    margin-top: 20px;
}

.navbar {
    /* background-color: #bed5dd; */
    /* background: url(./img/Untitled.png); */
    background: #1555A2;
    /* border-bottom: 1px solid rgba(150, 213, 216, 0.363); */
    /* box-shadow: 4px 10px 10px 10px white; */
    transition: border-bottom 0.5s linear;
    color: white;
    /* padding: 20px 0; */
    height: fit-content;
    transition: background-color 1s ease;
    padding: 8px;
    z-index: 99;
  }
  .navbar.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: #1555A2;
    color: #14a1d9;
    /* box-shadow: 10px 2px 15px 10px rgba(7, 150, 182, 0.497); */
    z-index: 999;
    padding: 8px;
  }
  .navbar.sticky .nav__link{
    color: white;
  }
  .navbar .managementLink{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  .navbar.sticky .managementLink{
    text-decoration: none;
    color: white;
  }

.brand-name{
    padding-left: 30px;
    /* padding-top: 10%;  */
}
.doniLogoBrand{
    /* padding-top: 20px; */
    width: fit-content;
}
.nav__menu{
    display: flex;
    align-items: center;
    /* justify-content: space-around;   */
    gap: 0.5rem;
    font-family: 'Nexa', sans-serif;  
    font-weight: 600;
    font-size: 13px;                                                                        
}
.nav__item{
    margin-left: 13px;
}
.nav__link{
    color: white;
    text-decoration: none;
    justify-content: center;
    padding-right: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
} 
.nav__link:hover{
    color: var(--primary);
    transition: 0.6s all ease;
}
.active{
    position: relative;
    display: inline-block;
    overflow: hidden;
    /* padding: 0.5rem 0; */
    color: var(--primary);
}
.navbar.sticky .active{
    color: var(--primary);
}
.active::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: var(--primary);
    transform: translateX(-30%); 
    transition: transform 0.6s ease-in-out;
}
.active:hover::before{
    transform: translateX(0);
}
.nav__toggler div{
    width: 2.6rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: white;
}
.nav__toggler {
    cursor: pointer;
    display: none;
}

.headerDesc{
    font-family: 'Nexa', sans-serif;  
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 0.5% 7%; 
    z-index: 999;
    position: absolute;
    width: 70%;
}
.material-symbols-outlined{
    font-size: 30px;
}
.fa-envelope-open{
    font-size: 25px;
}
.fa-phone-alt{
    font-size: 25px;
}
.fa-map-marker-alt{
    font-size: 25px;
}
.contactIcon{
    transform: rotate(-45deg);
}
.serviceContact{
    transform: rotate(45deg);
    background-color: var(--primary);
}
.not-found {
    font-size: 10rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: #472f1628;
    opacity: 0.4;
    margin: 0;
    line-height: 1;
    animation: bounce 2s infinite;
}
.loading-text {
    font-size: 1.5rem;
    color: #555;
}
.oops{
    color: rgb(144, 7, 7);
    opacity: 0; 
    animation: oops 2s ease infinite;
}
@keyframes oops {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

@media screen and (max-width: 820px) {
    *{
        margin: 0;
        padding: 0;
    }
    .navbar{
        height: 74px;
    }
    .navbar .nav__link{
        color: white;
        text-decoration: none;
    }
    .navbar .managementLink{
        color: white;
        text-decoration: none;
    }
    .navbar.sticky .nav__link{
        color: black;
        text-decoration: none;
    }
    .navbar.sticky .managementLink{
        color: black;
        text-decoration: none;
    }
    .brand-name{
        position: absolute;
        left: 7%;
        padding-left: 0;
    }
    .doniLogoBrand{
        padding: 0;
    }
    .nav{
        border-radius: 0;
        align-items: center;
        justify-content: space-around;
        top: 1%;
        left: 0;
    }
    .nav__toggler {
        display: block;
        position: absolute;
        right: 7%;
    }
    .nav__menu {
        position: fixed;
        top: 73px;
        right: 0; 
        width: 100%;
        height: 100vh;
        /* background-color: white; */
        /* background-image: url(./img/whiteMenu.jpg);  */
        background-color: #1555A2;
        background-repeat: no-repeat;
        background-size: cover;
        flex-direction: column;
        padding: 0;
        justify-content: center;
        transform: translateX(100%);
        transition: 0.4s ease-out;
        /* border-top: 1px solid rgba(221, 221, 208, 0.527); */
        z-index: 9; 
    }
    .navbar.sticky .nav__menu{
        font-size: 25px;
        /* border-top: 1px solid white; */
    }
    .nav{
        margin: 5% 0;
    }
    .sideMenu{
        width: 35px;
        margin: 0;
    }
    
    .headerDesc{
        position: absolute;
        top: 4px;
        /* color: black; */
        padding: 0;
        font-size: 11px;
    }
    .nav__item{
        margin-left: 0;
    }
    .staffBoard{
        display: flex;
        flex-direction: column;
    }
    .secondStaffBoard{
        display: flex;
        flex-direction: column;
    }
    .three_box{
        margin-top: 10px;
        padding: 0;
    }
    .custom-div{
        padding: 5px 0;
        width: 80%;
        margin: 15px auto;
    }
    .kryeshefi{
       display: flex;
       flex-direction: column-reverse;
    }
    .award_icon{
        width: fit-content;
    }
    .award_icon strong{
        width: fit-content;
    }
    .award_icon strong{
        font-size: 14px;
        text-align: center;
        margin: 0;
    }
    .logo-npl{
        width: 150px;
        object-fit: cover;
    }
}

@media screen and (min-width: 1500px) {
    .sideMenu{
        margin-left: 165px;
    }
    .nav__menu{
       margin-left: 30px;
    }
}

@media screen and (min-width: 1600px) {
    /* .nav__menu{
        margin-left: 125px;
     } */
}

@media screen and (min-width: 1800px) {
    .nav__menu{
        margin-left: 150px;
        gap: 2rem;
    }
    .sideMenu{
        margin-left: 170px;
    }
}

@media screen and (min-width: 2000px) {
    .nav__menu{
        font-size: 25px;
        margin-left: 100px;
    }
    .nav{
        height: 110px;
        top: 40px;
    }
    .doniLogoBrand{
        padding-top: 20%;
    }
    .sideMenu{
        width: 40px;
        margin-left: 160px;
    }
    .headerDesc{
        font-size: 17px;
    }
    .doniLogoBrand{
        padding-top: 30px;
    }
}



/* Nav Active Class */

.nav__active{
    transform: translateX(0);
}

/* Toggler Icon Animation */

.toggle .line1 {
    transform: rotate(-45deg) translate(-4px, 9px);
}
.toggle .line2 {
    opacity: 0;
}
.toggle .line3 {
    transform: rotate(45deg) translate(-4px, -9px);
}


.team-item .team-img img,
.blog-item .blog-img img  {
    transition: .5s;
}

.team-item:hover .team-img img,
.blog-item:hover .blog-img img {
    transform: scale(1.15);
}

.newsBg{
    background-image: url(./img/3slide.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.adsBg{
    background-image: url(./img/ads8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}
.staffBg{
    background-image: url(./img/boardd.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}
.aboutBg{
    background-image: url(./img/germia.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter:grayscale(60%);

    color: white;
}
.headingH1{
    /* background-color: #1555A2; */
    width: fit-content;
    margin: 0 auto;
    padding: 15px;
    border-radius: 20px;
}
.historyHeading{
    font-family: 'Roboto Light', sans-serif;  
}
.lawBg{
    background-image: url(./img/law2.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.historyPar{
    font-family: 'Roboto Light', sans-serif;  
    font-weight: 600;
    color: var(--darkBlack);
}
.staffPhoto1{
    margin-top: 25%;
}
.germiaSingle{
    background-image: url(./img/22.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter:grayscale(60%);
}
.tennisSingle{
    background-image: url(./img/tennis2023.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    filter:grayscale(60%);
}
.amfiteatriSingle{
    background-image: url(./img/amfiteatriAmfiteatri.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter:grayscale(60%);
}
.sportBackground{
    background-image: url(./img/Sport.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.errorPage{
    margin: 0 auto;
}
.bg-staff{
    /* background: var(--primary); */
    /* height: 60px; */
    padding: 10px 0;
    color: #6b6a75;
    font-family: "Nexa", sans-serif;
    font-weight: bold;
}
.bg-staff:hover{
    background-color: #6b6a75;
    transition: 2s ease;
}
.swimSingle{
    background-image: url(./img/swim3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    filter:grayscale(70%);
    background-position: center;
}
.skiSingle{
    background-image: url(./img/ski.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    filter:grayscale(60%);
    background-position: top;
}
.bg-tennisIcon{
    background-image: url(./img/tennisIcon2023.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-amfiteatriIcon{
    background-image: url(./img/amfiteatriNpl2023.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-notiIcon{
    background-image: url(./img/notiNpl2023.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-skiIcon{
    background-image: url(./img/skijimiIcon2023.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-campNpl{
    background-image: url(./img/akullNeVere.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-germia{
    background-image: url(./img/IKONA-01.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.kampshtepiza{
    background-image: url(./img/2023-2024.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    filter:grayscale(70%);
    background-position: top;
}
.bg-contact{
    background-color: #1555A2;
}
.blogImage{
    height: 30vh;
    object-fit: cover;
    width: 100%;
}
.singleImage{
    height: 60vh;
    object-fit:cover;
}
.singleAdsImage{
    object-fit: cover;
}
.galerySingle{
    height: 45vh;
}
.singleDiv{
    margin: 0 auto;
}
.galeryMap{
    transition: transform 1.5s ease;
}
.galeryMap:hover{
    transform: scale(1.05);
    mask-image: linear-gradient(-180deg, rgb(4, 0, 0), transparent 145%);
}




/* accordion */

  .accordion {
    /* border: 1px solid #ccc; */
    /* margin-bottom: 10px; */
    margin: 0 auto;
    padding: 8px 14px;
    margin-top: 12px;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto Light', sans-serif;  
    align-items: center;
    /* padding: 4px; */
    cursor: pointer;
    /* background-color:var(--primary); */
    border: none;
    color: black;
    /* border-radius: 5px; */
     /* background: rgb(235, 229, 229); */
     border-bottom: 1px solid #5CCE20;
     /* text-align: center; */
     margin: 0 auto;
  }
  .accordion-content {
    padding: 5px;
    /* background-color: rgba(186, 148, 148, 0.031); */
    /* border-radius: 5px; */
    font-family: 'Roboto Medium', sans-serif;  
    /* box-shadow: 1px 1px 1px 1px rgba(57, 51, 51, 0.387); */
    margin-top: 3px;
    font-weight: 300;
    justify-content: center;
    align-items: center;
   
  }
  .icon{
    color:#FF931E;
  }
  .linkHover{
    color: var(--primary);
  }
  .linkHover:hover{
    color: rgba(149, 143, 164, 0.805);
    font-size: 17px;
    transition: 0.5s all ease;
    margin: 4px;
  }

  .choose {
    background: url(./img/testWall.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 60px 0;
    margin-top: 50px;
}

.choose .titlepage {
    text-align: left;
}

.choose .titlepage h2 {
    padding-bottom: 30px;
}

.choose .titlepage p {
    color: #141414;
    font-weight: 400;
    font-family: 'Roboto Medium', sans-serif;  
    font-size: 17px;
    line-height: 32px;
    padding-bottom: 85px;
}
.award {
    display: flex;
    align-items: center;
    flex-wrap: wrap;  
}
.award_icon {
    width: 24%;
    padding: 10px 2px;
}
#awa_ho:hover.award_icon i {
    scale: 1.3;
    transition: ease-in-out all 0.5s;
}

.award_icon i {
    background-color: transparent;
    transition: ease-in all 0.5s;
    /* width: 147px; */
    height: 22vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.award_icon strong {
    color: #141414;
    font-family: 'Roboto Medium', sans-serif;  
    text-transform: uppercase;
    font-weight: 700;
    padding-top: 12px;
    display: block;
    text-align: center;
}

.stafImage{
    width: 100%;
    height: 335px; 
    object-fit: cover;
}
